import React, {useState, useRef} from 'react';
import {images} from '../../constants';
import {AppWrap, MotionWrap} from '../../wrapper';
import './Footer.scss';
import emailjs from 'emailjs-com';


const Footer = () => {
    const [formData, setFormData] = useState({ name: '', subject: '', company: '', phoneNumber: '', email: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { username, email, message, company, phoneNumber, subject } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const form = useRef();

    const sendEmail = (e) => {
        setLoading(true);
        e.preventDefault();

        emailjs.sendForm('service_uxjmgxo', 'template_talents', form.current, 'user_tv0CLQdhdLqVKFXNYavb3')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
        e.target.reset();
        setLoading(false);
        setIsFormSubmitted(true);
    };

    return (
        <>
            <h2 className="head-text">Une question? <br/>une suggestion ?</h2>

            <div className="app__footer-cards">
                <div className="app__footer-card ">
                    <img src={images.email} alt="email" />
                    <a href="mailto:contact@votre-forum-enligne.com" className="p-text">contact@votre-forum-enligne.com</a>
                </div>
                <div className="app__footer-card">
                    <img src={images.mobile} alt="phone" />
                    <a href="tel:+33 04 97 10 01 00" className="p-text">+33 09 88 00 48 05</a>
                </div>
            </div>

                {!isFormSubmitted ? (
                <form ref={form} onSubmit={sendEmail} className="app__footer-form app__flex">
                    <div className="app__flex">
                        <input
                                className="p-text"
                                type="text"
                                placeholder="Nom"
                                name="username"
                                value={username}
                                required={true}
                                onChange={handleChangeInput}
                        />
                    </div>
                    <div className="app__flex">
                        <input
                                className="p-text"
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={email}
                                required={true}
                                onChange={handleChangeInput}
                        />
                    </div>
                    <div className="app__flex">
                        <input
                                className="p-text"
                                type="text" placeholder="Sujet"
                                name="subject"
                                value={subject}
                                required={true}
                                onChange={handleChangeInput}
                        />
                    </div>
                    <div className="app__flex">
                        <input
                                className="p-text"
                                type="text"
                                placeholder="Société"
                                name="company"
                                value={company}
                                onChange={handleChangeInput}
                        />
                    </div>
                    <div className="app__flex">
                        <input
                                className="p-text"
                                type="text"
                                placeholder="Téléphone"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={handleChangeInput}
                        />
                    </div>
                    <div>
                        <textarea
                                className="p-text"
                                placeholder="Message"
                                value={message}
                                required={true}
                                name="message"
                                onChange={handleChangeInput}
                        />
                    </div>
                    <button type="submit" className="p-text">{!loading ? 'Envoyer' : 'En cours d\'envoi...'}</button>
                </form>
                ) : (
                        <div>
                            <h3 className="head-text">
                                Message transmis !
                            </h3>
                        </div>
                )}

        </>
    );
};

export default AppWrap(
    MotionWrap(Footer,  'app__footer'),
    'contact',
    'app__whitebg'
);
