import React, {useEffect, useState} from 'react';
import {AppWrap} from "../../wrapper";
import {client, urlFor} from "../../client";
import {motion} from "framer-motion";
import {PortableText} from "@portabletext/react";

const Solution = () => {

    const [solutions, setSolutions] = useState([]);

    useEffect(() => {
        const query = '*[_type == "solutions"]';

        client.fetch(query)
                .then((data) => setSolutions(data))
    }, []);


    return (
            <>
                <section id="solution" className="about-section-two style-two layout-pt-150 layout-pb-60 js-tab-menu-content">
                    <div className="auto-container">
                        <div className="grid-base justify-content-between align-items-center">
                            <div className="col-lg-6">
                                <div className="">
                                    <h2 className="fw-700">Une solution complète et personnalisée</h2>
                                    <h4>dédiée à votre événement</h4>
                                </div>
                            </div>
                            <div className="col-auto">
                                <a href={`/options`} className="button-icon -arrow">En savoir plus <span className="fa fa-angle-right"/></a>
                            </div>
                        </div>
                        <div className="row mt-50 wow fadeInUp">
                            {solutions.map((solution, index) => (
                                <div
                                        className="category-block-three -type-3 col-xl-3 col-lg-3 col-md-6 col-sm-12"
                                        key={solution.description + index}
                                >
                                    <div className="inner-box">
                                        <motion.div
                                                whileInView={{ opacity: [0, 1] }}
                                                transition={{ duration: 0.5 }}
                                                className="content"
                                        >
                                            <motion.img
                                                    whileInView={{ scale: 1, opacity: [0, 1] }}
                                                    transition={{ duration: 2, ease: 'easeInOut' }}
                                                    src={urlFor(solution.iconUrl)}
                                                    alt="icon"
                                                    className="solution-icon"
                                            />
                                            <PortableText value={solution.description} />
                                        </motion.div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

            </>
    );
};

export default AppWrap(Solution, 'solution');
