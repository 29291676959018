import React from 'react';
import {Navbar} from "../components";
import {Banner, Footer, PointsForts, Realisation, Solution} from "../container";

const Home = () => {
    return (
            <div className="app">
                <Navbar />
                <Banner />
                <Solution />
                <PointsForts />
                <Realisation />
                <Footer />
            </div>
    );
};

export default Home;
