import React from 'react';
import './App.scss';
import './css/index'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import Page from "./pages/Page";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

const App = () => {
    return (
            <BrowserRouter>
                <AnimatePresence>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/:slug" children={<Page />}/>
                        <Route component={NotFound}/>
                    </Switch>
                </AnimatePresence>
            </BrowserRouter>
    );
};

export default App;
