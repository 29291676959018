import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
    <div className="app__social">
        <div>
            <a href="https://twitter.com/TalentsHandicap" target="_blank" rel="noreferrer"><BsTwitter /></a>
        </div>
        <div>
            <a href="https://www.facebook.com/talents.handicap" target="_blank" rel="noreferrer"><FaFacebookF /></a>
        </div>
        <div>
            <a href="https://www.instagram.com/talents.handicap/" target="_blank" rel="noreferrer"><BsInstagram /></a>
        </div>
    </div>
);

export default SocialMedia;
