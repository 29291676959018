import React from 'react';
import {Link} from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";

const NotFound = () => {
    return (
            <AnimatedPage>
                <div className="auto-container">
                    <h1>Cette page n'existe pas</h1>
                    <br/>
                    <Link to="/" className="theme-btn btn-style-one bg-blue">Revenir à l'accueil</Link>
                </div>
            </AnimatedPage>
    );
};

export default NotFound;
