import React, {useEffect, useState} from 'react';
import {AiFillEye} from "react-icons/ai";
import {motion} from "framer-motion";

import {AppWrap} from '../../wrapper';
import {urlFor, client} from "../../client";
import './Realisation.scss'

const Realisation = () => {

    const [activeFilter, setActiveFiltrer] = useState('tous')
    const [animateCard, setAnimateCard] = useState({y: 0, opacity: 1})
    const [realisations, setRealisations] = useState([]);
    const [filterRealisation, setFilterRealisation] = useState([]);

    useEffect(() => {
        const query = '*[_type == "realisations"]';
        client.fetch(query)
            .then((data) => {
                setRealisations(data);
                setFilterRealisation(data);
            })
    }, []);

    const handleRealisationFilter = (item) => {
        setActiveFiltrer(item);
        setAnimateCard([{y: 100, opacity: 0}]);

        setTimeout(() => {
            setAnimateCard([{y: 0, opacity: 1}]);

            if (item === 'All') {
                setFilterRealisation(realisations);
            } else {
                setFilterRealisation(realisations.filter((realisation) => realisation.tags.includes(item)))
            }
        }, 500);
    }

    return (
        <>
            <h2 className="head-text">Parmi <span>nos réalisations</span></h2>

            <div className="app__realisation-filter">
                {['education', 'economie' , 'emploi' , 'tous'].map((item,  index) => (
                    <div
                        key={index}
                        onClick={() => handleRealisationFilter(item)}
                        className={`app__realisation-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : '' }`}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <motion.div
                animate={animateCard}
                transition={{duration: 0.5, delayChildren: 0.5}}
                className="app__realisation-portfolio"
            >
                {filterRealisation.map((realisation, index) => (
                        <motion.div
                                className="app__realisation-item app__flex"
                                whileHover={{scale: [1, 0.9]}}
                                transition={{duration: 0.2, ease: 'easeInOut'}}
                                key={index}>
                        <div className="app__realisation-img app__flex">
                            <img src={urlFor(realisation.imgUrl)} alt={realisation.name} />
                        </div>
                        <div className="app__realisation-content app__flex">
                            <h4 className="bold-text">{realisation.title}</h4>
                            <p className="p-text" style={{marginTop: 10}}>{realisation.description}</p>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </>
    );
};

export default AppWrap(Realisation,  'realisations');
