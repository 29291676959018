import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import './PointsForts.scss';
import {urlFor, client} from "../../client";
import {PortableText} from "@portabletext/react";

const PointsForts = () => {
    const [points, setPoints] = useState([]);

    useEffect(() => {
        const query = '*[_type == "points"]';

        client.fetch(query)
            .then((data) => setPoints(data))
    }, []);

    return (
        <>
            <section id="pointsforts" className="layout-pt-120 layout-pb-120">
                <div className="auto-container">
                    <div className="text-center">
                        <h2 className="head-text">Les <span>points forts</span> de Votre forum en ligne ® </h2>
                        <div className="text" style={{marginTop: 20}}>Une expérience de plus de 10 ans dans les événements en ligne et une organisation basée sur la pleine satisfaction du client nous permettent d’être votre meilleur partenaire pour réussir l’organisation sur-mesure de votre forum en ligne ou de votre salon virtuel</div>
                    </div>


                    <div className="row grid-base pt-50 wow fadeInUp">
                        {points.map((point, index) => (
                                <motion.div
                                        whileInView={{ opacity: 1 }}
                                        whileHover={{ scale: 1.1 }}
                                        transition={{ duration: 0.5, type: 'tween' }}
                                        className="col-lg-4 col-md-6 col-sm-12"
                                        key={point.title + index}
                                >
                                    <div className="work-block -type-3">
                                        <div className="icon-wrap">
                                            <img src={urlFor(point.iconUrl)} alt={point.title} />
                                        </div>
                                        <div className="auto-container">
                                            <h2 className="bold-text" style={{ marginTop: 20 }}>{point.title}</h2>
                                            <div  style={{ marginTop: 10 }}><PortableText value={point.description} /></div>
                                        </div>
                                    </div>
                                </motion.div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default AppWrap(
    MotionWrap(PointsForts, 'app__point'),
    'points forts',
    'app__whitebg',
);
