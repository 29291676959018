import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: 'c5g8wy50',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: 'skv9qB7kpfUF8vrMDID7DF9sczI95GyZ2zBFTwfJ4BtHJBviTzvoXwTrTtjWXo6VJkrvD6AQMusTpyv2qmJKmwNr50rVOnarSXpPC0EB7EbQ2XUiZsBL6kweSidyhQvJvDGIkDagdQTwmRBJLf1R6yltboBFHzHScV49Dt5HGVbK6YJCQx1i',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
