import React, {useEffect, useState} from 'react';
import { Link, useParams } from "react-router-dom"
import {client} from "../client";

const Page = () => {

    const [singlePage, setSinglePage] = useState([]);
    const { slug } = useParams();

    useEffect(() => {
        const Query = '*[slug.current == "${slug}"]';

        client.fetch(Query)
                .then((data) => {
                    setSinglePage(data);
                })
    }, [slug]);

    return (
            <div>
                <p>{singlePage.title}</p>
            </div>
    );
};

export default Page;
