import React, {useEffect, useState} from 'react';
import { motion } from 'framer-motion';
import { PortableText } from '@portabletext/react'

import {AppWrap, MotionWrap} from '../../wrapper';
import { images } from '../../constants';
import {client, urlFor} from "../../client";

const Banner = () => {
    const [banners, setBanners] = useState([]);
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const query = '*[_type == "banners"]';
        const brandsQuery = '*[_type == "brands"]';
        client.fetch(query)
                .then((data) => {
                    setBanners(data);
                })
        client.fetch(brandsQuery)
                .then((data) => {
                    setBrands(data);
                })
    }, []);



    return (
            <>
                <section className="banner-section-three -type-12">
                    {banners.map((banner, index) => (
                            <div key={banner.title + index}>
                                <div className="bg-image">
                                    <motion.img
                                            whileInView={{ scale: [1, 2], opacity: [0, 1] }}
                                            transition={{ duration: 2, ease: 'easeInOut' }}
                                            src={images.banner_bg}
                                            alt={banner.title}
                                    />
                                    <figure className="main-image wow fadeInRight" data-wow-delay="1500ms">
                                        <motion.img
                                                whileInView={{ scale: 1, opacity: [0, 1] }}
                                                transition={{ duration: 4, ease: 'easeInOut' }}
                                                src={urlFor(banner.imgUrl)}
                                                alt={banner.title}
                                        />
                                    </figure>
                                </div>
                                <div className="auto-container">
                                    <div className="row">
                                        <div className="content-column col-lg-7 col-md-12 col-sm-12">
                                            <div className="inner-column">
                                                <motion.div
                                                        whileInView={{ opacity: 1 }}
                                                        whileHover={{ scale: 1 }}
                                                        transition={{ duration: 2.5, type: 'tween' }}
                                                        className="title-box wow fadeInUp"
                                                >
                                                    <h3>{banner.title}</h3>
                                                    <div className="text">
                                                        <p className="fw-700">{banner.subtitle}</p><br/>
                                                        <div className="p-banner-responsive">
                                                            <PortableText value={banner.description} />
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <a href={`/#${banner.buttonlink}`} className="theme-btn -blue-light">{banner.button}</a>
                                                </motion.div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    ))}
                </section>
                <section className="clients-section-two alternate layout-pt-40">
                    <div className="auto-container">
                        <div className="sponsors-outer wow fadeInUp">
                            <ul className="sponsors-carousel owl-carousel owl-theme">
                                {brands.map((brand) => (
                                        <li className="slide-item" key={brand.name}>
                                            <figure className="image-box">
                                                <img src={urlFor(brand.imgUrl)} alt={brand.name} />
                                            </figure>
                                        </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </>
    );
};

export default AppWrap(
        MotionWrap(Banner, 'app__about'),
        'accueil',
        'app__whitebg',
);
